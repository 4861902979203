import { defineStore } from 'pinia'
import { getScriptFilters, getScriptsByCustomer } from '@/api/scripts.js'
import { getCustomers, getRightsByCustomer } from '@/api/rights.js'
import { getSelectableContacts } from '@/api/contacts.js'
import { getCallTeams, getCallQueues } from '@/api/reports.js'

export const useSelectStore = defineStore('selects', {
   persist: true,
   state: () => ({
      customerID: null,
      scriptID: null,
      contactID: null,
      filters: [],
      rightGroups: [],
      teams: [],
      queues: [],
      customerArr: [],
      scriptArr: [],
      filterArr: [],
      contactArr: [],
      rightGroupArr: [],
      teamArr: [],
      queueArr: [],
   }),
   getters: {
      getTeamIDs: (state) => {
         return state.teams.map(team => team.team_id)
      },
      getTeamNames: (state) => {
         return state.teams.map(team => team.team_name)
      },
      getQueueIDs: (state) => {
         return state.queues.map(queue => queue.queue_id)
      },
      getQueueNames: (state) => {
         return state.queues.map(queue => queue.queue_name)
      },
   },
   actions: {
      setCustomerID (customerID) {
         this.customerID = customerID
      },
      /** Only set the customerID if there is none set already **/
      customerSingleton (customerID) {
         if (!this.customerID) {
            this.setCustomerID(customerID)
         }
      },
      setScriptID (scriptID) {
         this.scriptID = scriptID
      },
      setFilters (filters) {
         this.filters = filters
      },
      setContactID (contact) {
         this.contactID = contact
      },
      setRightGroups (rightGroups) {
         this.rightGroups = rightGroups
      },
      setTeams (teams) {
         this.teams = teams
      },
      setQueues (queues) {
         this.queues = queues
      },
      async fetchCustomerArr () {
         this.customerArr = await getCustomers()
      },
      async fetchScriptArr () {
         if (this.customerID) {
            this.scriptArr = await getScriptsByCustomer(this.customerID)
         }
      },
      async fetchFilterArr () {
         this.filterArr = []
         this.filterArr = await getScriptFilters(this.scriptID)
      },
      async fetchContactArr () {
         this.contactArr = []
         this.contactArr = await getSelectableContacts(this.customerID)
      },
      async fetchRightGroupArr () {
         this.rightGroupArr = []
         this.rightGroupArr = await getRightsByCustomer(this.customerID)
      },
      async fetchTeamArr () {
         this.teamArr = await getCallTeams(this.customerID)
      },
      async fetchQueueArr () {
         this.queueArr = await getCallQueues(this.customerID, this.getTeamIDs)
      },
   },
})
