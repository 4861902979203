import { defineStore } from 'pinia'
import { useSelectStore } from './selects.js'
import { updateUserSettings, getUserInfo } from '@/api/login.js'

export const useUserStore = defineStore('user', {
   persist: true,
   state: () => ({
      rights: [],
      contactInfo: {},
      settings: {
         monitorHeaders: [],
         batchHeaders: [],
         smallMenuToggle: false,
         callReport: {},
         wallboard: {},
      },
      menuToggleSate: true,
      notification: null,
   }),
   getters: {
      checkRights: (state) => (rightObjectID) => {
         return (state.rights.indexOf(rightObjectID) !== -1)
      },
      getSmallMenuToggle: (state) => {
         return state.settings.smallMenuToggle
      },
      getMonitorHeaders: (state) => {
         return state.settings.monitorHeaders
      },
      displayedMonitorHeaders: (state) => {
         return state.settings.monitorHeaders.filter(x => {
            return x.class !== 'd-none'
         })
      },
      getBatchHeaders: (state) => {
         return state.settings.batchHeaders
      },
      displayBatchHeader: (state) => {
         return state.settings.batchHeaders.filter(x => {
            return x.class !== 'd-none'
         })
      },
      getCallReportSetting: (state) => (property) => {
         const select = useSelectStore()
         return state.settings.callReport[select.customerID]?.[property]
      },
      getWallboardSetting: (state) => (property) => {
         const select = useSelectStore()
         return state.settings.wallboard[select.customerID]?.[property]
      },
   },
   actions: {
      async initUser () {
         const { rights, contactInfo, settings, customerID } = await getUserInfo()

         this.rights = rights
         this.contactInfo = contactInfo

         /** attempt to loop through the settings keys **/
         if (settings) {
            const settingKeys = Object.keys(settings)
            if (settingKeys.length > 0) {
               for (const key of settingKeys) {
                  this.settings[key] = settings[key]
               }
            }
         }

         const select = useSelectStore()
         await select.fetchCustomerArr()
         if (this.checkRights(Number(`5${customerID}`))) {
            select.customerSingleton(customerID)
         }
         await select.fetchScriptArr()
      },
      async saveSettings () {
         await updateUserSettings({
            settings: this.settings,
         })
      },
      async toggleMenu (value) {
         if (value === undefined) {
            value = !this.settings.smallMenuToggle
         }
         this.settings.smallMenuToggle = value

         await this.saveSettings()
      },
      toggleMenuState (value) {
         if (value === undefined) {
            value = !this.menuToggleSate
         }
         this.menuToggleSate = value
      },
      showNotification (value) {
         this.notification = value
      },
      initMonitorHeaders (headers) {
         if (this.settings.monitorHeaders.length > 0) {
            /** loop the current headers and see if columns match w/ index **/
            this.settings.monitorHeaders = headers.map(header => {
               this.settings.monitorHeaders.forEach(storedHeader => {
                  if (header.value === storedHeader.value) {
                     header = storedHeader
                  }
               })
               return header
            })
         } else {
            this.settings.monitorHeaders = headers
         }
      },
      initBatchHeaders (headers) {
         if (this.settings.batchHeaders.length > 0) {
            /** loop the current headers and see if columns match w/ index **/
            this.settings.batchHeaders = headers.map(header => {
               this.settings.batchHeaders.forEach(storedHeader => {
                  if (header.value === storedHeader.value) {
                     header = storedHeader
                  }
               })
               return header
            })
         } else {
            this.settings.batchHeaders = headers
         }
      },
      async setMonitorHeaders (headers) {
         this.settings.monitorHeaders = headers
         await this.saveSettings()
      },
      async setBatchHeaders (headers) {
         this.settings.batchHeaders = headers
         await this.saveSettings()
      },
      async setCallReportSettings (key, value) {
         const select = useSelectStore()
         this.settings.callReport = Object.assign({}, this.settings.callReport, { [select.customerID]: { ...this.settings.callReport?.[select.customerID], [key]: value } })
         await this.saveSettings()
      },
      async setWallboardSettings (payload) {
         const select = useSelectStore()
         const { queues, multi } = payload
         this.settings.wallboard = Object.assign({}, this.settings.wallboard, { [select.customerID]: { queues, multi } })
         await this.saveSettings()
      },
   },
})
