<template>
   <v-app>
      <router-view />
      <Notification />
   </v-app>
</template>

<script>
   import { storeToRefs } from 'pinia'
   import { useLocaleStore } from '@/stores/locale.js'
   import { sendPing } from '@/api/login.js'
   import Notification from '@/components/Notification.vue'

   export default {
      components: {
         Notification,
      },
      setup () {
         const localeStore = useLocaleStore()

         const { locale } = storeToRefs(localeStore)
         return { localeStore, locale }
      },
      data () {
         return {
            keepalive: null,
         }
      },
      async created () {
         await this.initTranslations()
         this.keepalive = setInterval(sendPing, 1800000)
      },
      beforeDestroy () {
         clearInterval(this.keepalive)
      },
      methods: {
         async initTranslations () {
            const msg = await import(`@/locales/${this.locale}.js`)
            await this.localeStore.setLocale(this.locale)
            this.$i18n.setLocaleMessage(this.locale, msg.default)
            this.$i18n.locale = this.locale
         },
      },
   }
</script>

<style lang="scss">
   /** remove Vuetify default scrollbar **/
   html {
      overflow-y: auto !important;
   }

   /** apply slightly darker background **/
   .v-application.theme--light {
      background: #EEEEEE !important;
   }

   .pointer {
      cursor: pointer;
   }

   .relative {
      position: relative;
   }
</style>
