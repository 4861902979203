import { HTTP } from '@/http.js'

export async function sendPing () {
   const { data } = await HTTP.get('/login/ping')
   return data
}

export async function updateLocale (payload) {
   const { data } = HTTP.put('/login/locale', payload)
   return data
}

export async function updateUserSettings (payload) {
   const { data } = await HTTP.put('/login/settings', payload)
   return data
}

export async function logoutUser () {
   const { data } = await HTTP.put('/login/logout')
   return data
}

export async function loginUser (username, password) {
   const { data } = await HTTP.post('/login', {
      username,
      password,
   })
   return data
}

export async function getUserInfo () {
   const { data } = await HTTP.get('/login/info')
   return data
}

export async function sendReset (username, locale, sendMail = true) {
   const { data } = await HTTP.post('/login/reset', {
      username,
      locale,
      sendMail,
   })
   return data
}

export async function replacePassword (oldPassword, newPassword) {
   const { data } = await HTTP.put('/login/replace', {
      oldPassword,
      newPassword,
   })
   return data
}

export async function getTokenStatus (token) {
   const { data } = await HTTP.get(`/login/change/${token}`)
   return data
}

export async function changeUser (payload) {
   const { data } = await HTTP.put('/login/change', payload)
   return data
}

export async function generateAuthenticator () {
   const { data } = await HTTP.post('/login/2fa/app')
   return data
}

export async function activateTwoFactor (token, type) {
   const { data } = await HTTP.put(`/login/2fa/${type}`, {
      token,
   })
   return data
}

export async function getTwoFactors () {
   const { data } = await HTTP.get('/login/2fa')
   return data
}

export async function removeTwoFactor (type, password, token) {
   const { data } = await HTTP.delete('/login/2fa', {
      data: {
         type,
         password,
         token,
      },
   })
   return data
}

export async function verifyOTP (token) {
   const { data } = await HTTP.post('/login/otp', {
      token,
   })
   return data
}

export async function generateOTP (type) {
   const { data } = await HTTP.post(`/login/otp/${type}`)
   return data
}

export async function addTwoFactor (issuer, type) {
   const { data } = await HTTP.post(`/login/2fa/${type}`, {
      issuer,
   })
   return data
}
