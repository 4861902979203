import Vue from 'vue'
import Router from 'vue-router'
import {
   mdiRadar,
   mdiMagnify,
   mdiViewHeadline,
   mdiFileDocument,
   mdiPaperclip,
   mdiUpload,
   mdiContacts,
   mdiMessageProcessing,
   mdiShieldCheck,
   mdiFilmstripBox,
   mdiChartBox,
   mdiCreditCard,
   mdiEmail,
   mdiWhatsapp,
   mdiCloudPrint,
   mdiFolderPlus,
   mdiMonitorDashboard,
   mdiAccountVoice,
   mdiPhonePlus,
   mdiCardAccountDetails,
   mdiPhone,
   mdiPhoneInTalk,
   mdiAccountCogOutline,
} from '@mdi/js'
import { useUserStore } from '@/stores/user.js'
import { sendPing } from '@/api/login.js'

Vue.use(Router)

async function checkLogin (to, from, next) {
   const { loggedIn, enforce2fa } = await sendPing()
   const query = to.fullPath !== '/' ? { redirect: to.fullPath } : null

   if (enforce2fa) {
      next({ name: '2fa', query })
   } else if (loggedIn) {
      const user = useUserStore()
      await user.initUser()
      next()
   } else {
      next({ path: '/login', query })
   }
}

const router = new Router({
   mode: 'history',
   routes: [
      {
         path: '/login',
         component: () => import(/* webpackChunkName: "login" */ '@/views/login/LoginLayout.vue'),
         children: [
            {
               path: '/',
               name: 'login',
               component: () => import(/* webpackChunkName: "login" */ '@/views/login/Login.vue'),
            },
            {
               path: 'forgot',
               component: () => import(/* webpackChunkName: "login" */ '@/views/login/ForgotPassword.vue'),
            },
            /** Not part of the /login/ URL, it only borrows the layout. **/
            {
               path: '/change/:passwordToken',
               name: 'change',
               component: () => import(/* webpackChunkName: "login" */ '@/views/login/ChangePassword.vue'),
            },
            {
               path: 'otp',
               name: 'otp',
               component: () => import(/* webpackChunkName: "login" */ '@/views/login/Otp.vue'),
            },
            {
               path: '2fa',
               name: '2fa',
               component: () => import(/* webpackChunkName: "login" */ '@/views/login/Enforce2fa.vue'),
            },
         ],
      },
      {
         path: '/',
         name: 'Dashboard',
         component: () => import(/* webpackChunkName: "core" */ '@/views/dashboard/DashboardLayout.vue'),
         beforeEnter: checkLogin,
         children: [
            {
               path: 'batches',
               component: () => import(/* webpackChunkName: "core" */ '@/views/dashboard/batches/Batches.vue'),
               meta: {
                  title: 'breadcrumbs.batches',
                  icon: mdiViewHeadline,
               },
            },
            {
               path: 'batches/:batchID',
               component: () => import(/* webpackChunkName: "core" */ '@/views/dashboard/batches/BatchInfo.vue'),
               meta: {
                  title: 'breadcrumbs.batchInfo',
                  icon: mdiViewHeadline,
                  back: true,
               },
            },
            {
               path: 'scripts',
               component: () => import(/* webpackChunkName: "core" */ '@/views/dashboard/scripts/Scripts.vue'),
               meta: {
                  title: 'breadcrumbs.scripts',
                  icon: mdiFileDocument,
               },
            },
            {
               path: 'scripts/:scriptID',
               component: () => import(/* webpackChunkName: "core" */ '@/views/dashboard/scripts/ScriptInfo.vue'),
               meta: {
                  title: 'breadcrumbs.scriptInfo',
                  icon: mdiFileDocument,
                  back: true,
               },
            },
            {
               path: 'scripts/:scriptID/times/:windowID?',
               component: () => import(/* webpackChunkName: "core" */ '@/views/dashboard/scripts/TimeWindow.vue'),
               meta: {
                  title: 'breadcrumbs.timeWindows',
                  icon: mdiFileDocument,
                  back: true,
               },
            },
            {
               path: 'communications',
               component: () => import(/* webpackChunkName: "core" */ '@/views/dashboard/communication/Communications.vue'),
               meta: {
                  title: 'breadcrumbs.sendMessage',
                  icon: mdiPhonePlus,
               },
            },
            {
               path: 'monitor',
               component: () => import(/* webpackChunkName: "core" */ '@/views/dashboard/monitor/Monitor.vue'),
               meta: {
                  title: 'breadcrumbs.monitor',
                  icon: mdiRadar,
               },
            },
            {
               path: 'search',
               component: () => import(/* webpackChunkName: "core" */ '@/views/dashboard/SearchCase.vue'),
               meta: {
                  title: 'breadcrumbs.searchCase',
                  icon: mdiMagnify,
               },
            },
            {
               path: 'files/upload',
               name: 'file_upload',
               component: () => import(/* webpackChunkName: "core" */ '@/views/dashboard/FileUpload.vue'),
               meta: {
                  title: 'breadcrumbs.fileUpload',
                  icon: mdiUpload,
               },
            },
            {
               path: 'files/list',
               component: () => import(/* webpackChunkName: "core" */ '@/views/dashboard/Files.vue'),
               meta: {
                  title: 'breadcrumbs.fileList',
                  icon: mdiPaperclip,
               },
            },
            {
               path: 'contacts',
               component: () => import(/* webpackChunkName: "core" */ '@/views/dashboard/contacts/ContactView.vue'),
               meta: {
                  title: 'breadcrumbs.contacts',
                  icon: mdiContacts,
               },
            },
            {
               path: 'account',
               component: () => import(/* webpackChunkName: "core" */ '@/views/dashboard/account/SettingsView.vue'),
               meta: {
                  title: 'breadcrumbs.account',
                  icon: mdiAccountCogOutline,
               },
            },
            {
               path: 'admin/demo',
               name: 'demo_list',
               component: () => import(/* webpackChunkName: "admin" */ '@/views/dashboard/demo/ShowDemos.vue'),
               meta: {
                  title: 'breadcrumbs.demoList',
                  icon: mdiPhone,
               },
            },
            {
               path: 'admin/demo/:scriptID',
               name: 'demo',
               component: () => import(/* webpackChunkName: "admin" */ '@/views/dashboard/demo/Demo.vue'),
               meta: {
                  title: 'breadcrumbs.demo',
                  icon: mdiPhone,
                  back: true,
               },
            },
            {
               path: 'admin/whatsappweb',
               name: 'whatsappweb',
               component: () => import(/* webpackChunkName: "admin" */ '@/views/dashboard/whatsappweb/Pairing.vue'),
               meta: {
                  title: 'breadcrumbs.whatsappweb',
                  icon: mdiWhatsapp,
               },
            },
            {
               path: 'sms',
               component: () => import(/* webpackChunkName: "core" */ '@/views/dashboard/sms/SMS.vue'),
               meta: {
                  title: 'breadcrumbs.sms',
                  icon: mdiMessageProcessing,
               },
            },
            {
               path: 'reports/mail',
               component: () => import(/* webpackChunkName: "reports" */ '@/views/dashboard/reports/MailReports.vue'),
               meta: {
                  title: 'breadcrumbs.mailReport',
                  icon: mdiEmail,
               },
            },
            {
               path: 'reports/whatsapp',
               component: () => import(/* webpackChunkName: "reports" */ '@/views/dashboard/reports/WhatsAppReports.vue'),
               meta: {
                  title: 'breadcrumbs.whatsAppReport',
                  icon: mdiWhatsapp,
               },
            },
            {
               path: 'reports/payment',
               component: () => import(/* webpackChunkName: "reports" */ '@/views/dashboard/reports/PaymentReports.vue'),
               meta: {
                  title: 'breadcrumbs.paymentReport',
                  icon: mdiCreditCard,
               },
            },
            {
               path: 'reports/cases',
               component: () => import(/* webpackChunkName: "reports" */ '@/views/dashboard/reports/CaseReports.vue'),
               meta: {
                  title: 'breadcrumbs.caseReport',
                  icon: mdiChartBox,
               },
            },
            {
               path: 'reports/call',
               component: () => import(/* webpackChunkName: "reports" */ '@/views/dashboard/reports/CallReports.vue'),
               meta: {
                  title: 'breadcrumbs.callReport',
                  icon: mdiPhoneInTalk,
               },
            },
            {
               path: 'reports/batches',
               component: () => import(/* webpackChunkName: "reports" */ '@/views/dashboard/reports/results/ResultReports.vue'),
               meta: {
                  title: 'breadcrumbs.batchReport',
                  icon: mdiFilmstripBox,
               },
            },
            {
               path: 'reports/batches/:batchID',
               component: () => import(/* webpackChunkName: "reports" */ '@/views/dashboard/reports/results/ResultMapping.vue'),
               meta: {
                  title: 'breadcrumbs.batchMapping',
                  icon: mdiFilmstripBox,
                  back: true,
               },
            },
            {
               path: 'reports/batches/:batchID/output',
               name: 'batchOutput',
               component: () => import(/* webpackChunkName: "reports" */ '@/views/dashboard/reports/results/ResultOutput.vue'),
               meta: {
                  title: 'breadcrumbs.batchOutput',
                  icon: mdiFilmstripBox,
                  back: true,
               },
            },
            {
               path: 'reports/scripts/:scriptID/:start/:end',
               component: () => import(/* webpackChunkName: "reports" */ '@/views/dashboard/reports/results/ResultMapping.vue'),
               meta: {
                  title: 'breadcrumbs.scriptMapping',
                  icon: mdiFilmstripBox,
                  back: true,
               },
            },
            {
               path: 'reports/scripts/:scriptID/:start/:end/output',
               name: 'scriptOutput',
               component: () => import(/* webpackChunkName: "reports" */ '@/views/dashboard/reports/results/ResultOutput.vue'),
               meta: {
                  title: 'breadcrumbs.scriptOutput',
                  icon: mdiFilmstripBox,
                  back: true,
               },
            },
            {
               path: 'blacklist',
               component: () => import(/* webpackChunkName: "core" */ '@/views/dashboard/BlackList.vue'),
               meta: {
                  title: 'breadcrumbs.blacklist',
                  icon: mdiShieldCheck,
               },
            },
            {
               path: 'cases/new',
               name: 'new_case',
               component: () => import(/* webpackChunkName: "core" */ '@/views/dashboard/NewCase.vue'),
               meta: {
                  title: 'breadcrumbs.newCase',
                  icon: mdiFolderPlus,
               },
            },
            {
               path: 'whatsapp',
               component: () => import(/* webpackChunkName: "core" */ '@/views/dashboard/WhatsApp/WhatsApp.vue'),
               meta: {
                  title: 'breadcrumbs.whatsApp',
                  icon: mdiWhatsapp,
               },
            },
            {
               path: 'manager',
               component: () => import(/* webpackChunkName: "core" */ '@/views/dashboard/user_manager/Users.vue'),
               meta: {
                  title: 'breadcrumbs.manager',
                  icon: mdiCardAccountDetails,
               },
            },
            {
               path: 'manager/add',
               component: () => import(/* webpackChunkName: "core" */ '@/views/dashboard/user_manager/AddUser.vue'),
               meta: {
                  title: 'breadcrumbs.addUser',
                  icon: mdiCardAccountDetails,
                  back: true,
               },
            },
            {
               path: 'manager/edit',
               component: () => import(/* webpackChunkName: "core" */ '@/views/dashboard/user_manager/RightGroups.vue'),
               meta: {
                  title: 'breadcrumbs.editRights',
                  icon: mdiCardAccountDetails,
                  back: true,
               },
            },
            {
               path: 'admin/tts',
               component: () => import(/* webpackChunkName: "admin" */ '@/views/dashboard/TTS.vue'),
               meta: {
                  title: 'breadcrumbs.tts',
                  icon: mdiAccountVoice,
               },
            },
            {
               path: 'wordcloud',
               component: () => import(/* webpackChunkName: "extra" */ '@/views/dashboard/wordcloud/WordCloud.vue'),
               meta: {
                  title: 'breadcrumbs.wordcloud',
                  icon: mdiCloudPrint,
               },
            },
            {
               path: 'wallboard',
               component: () => import(/* webpackChunkName: "extra" */ '@/views/dashboard/wallboard/WallBoard.vue'),
               meta: {
                  title: 'breadcrumbs.wallboard',
                  icon: mdiMonitorDashboard,
               },
            },
         ],
      },
      {
         path: '/maintenance',
         component: () => import(/* webpackChunkName: "core" */ '@/views/dashboard/Maintenance.vue'),
      },
      {
         path: '/wallboard/full',
         component: () => import(/* webpackChunkName: "extra" */ '@/views/dashboard/wallboard/WallBoard.vue'),
         beforeEnter: checkLogin,
      },
      {
         path: '/wordcloud/full',
         component: () => import(/* webpackChunkName: "extra" */ '@/views/dashboard/wordcloud/WordCloud.vue'),
         beforeEnter: checkLogin,
      },
      {
         path: '/editor/:type',
         name: 'editor',
         component: () => import(/* webpackChunkName: "editor" */ '@/views/template_editor/EditorLayout.vue'),
         beforeEnter: checkLogin,
      },
      {
         path: '*',
         redirect: '/',
      },
   ],
})
router.onError(() => {
   /**
    * If an component has been modified while redeploying, an error will be thrown: "Loading chunk x failed"
    * hard reload the browser the pending path
    */
   location.replace(router.history.pending.path)
})

export default router
