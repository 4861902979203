import { HTTP } from '@/http.js'

export async function getRightsByCustomer (customerID) {
   const { data } = await HTTP.get('/rights/groups', {
      params: {
         customerID,
      },
   })
   return data
}

export async function getCustomers () {
   const { data } = await HTTP.get('/rights/customers')
   return data
}

export async function addRightGroup (payload) {
   const { data } = await HTTP.post('/rights/groups', payload)
   return data
}

export async function getRightsByGroup (rightGroupID) {
   const { data } = await HTTP.get(`/rights/groups/${rightGroupID}`)
   return data
}

export async function updateRightGroup (rightGroupID, payload) {
   const { data } = await HTTP.put(`/rights/groups/${rightGroupID}`, payload)
   return data
}

export async function deleteRightGroupByID (rightGroupID) {
   const { data } = await HTTP.delete(`/rights/groups/${rightGroupID}`)
   return data
}

export async function addRightGroupMembership (rightGroupID, payload) {
   const { data } = await HTTP.post(`/rights/groups/${rightGroupID}/members`, payload)
   return data
}

export async function deleteRightGroupMembership (rightGroupID, payload) {
   const { data } = await HTTP.delete(`/rights/groups/${rightGroupID}/members`, {
      data: payload,
   })
   return data
}

export async function addRight (rightObjectID, rightGroupID) {
   const { data } = await HTTP.post(`/rights/groups/${rightGroupID}/objects/${rightObjectID}`)
   return data
}

export async function deleteRight (rightObjectID, rightGroupID) {
   const { data } = await HTTP.delete(`/rights/groups/${rightGroupID}/objects/${rightObjectID}`)
   return data
}

export async function createRightObjectType (payload) {
   const { data } = await HTTP.post('/rights/types', payload)
   return data
}

export async function updateRightGroupType (rightObjectTypeID, payload) {
   const { data } = await HTTP.put(`/rights/types/${rightObjectTypeID}`, payload)
   return data
}

export async function deleteRightObjectType (rightObjectTypeID) {
   const { data } = await HTTP.delete(`/rights/types/${rightObjectTypeID}`)
   return data
}

export async function createRightObject (payload) {
   const { data } = await HTTP.post('/rights/objects', payload)
   return data
}

export async function updateRightObject (rightObjectID, payload) {
   const { data } = await HTTP.put(`/rights/objects/${rightObjectID}`, payload)
   return data
}

export async function deleteRightObject (rightObjectID) {
   const { data } = await HTTP.delete(`/rights/objects/${rightObjectID}`)
   return data
}
