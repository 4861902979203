<template>
   <v-snackbar v-model="display" top>
      {{ message }}
      <template #action>
         <v-btn text color="info" @click="display = false">
            <v-icon>{{ mdiClose }}</v-icon>
         </v-btn>
      </template>
   </v-snackbar>
</template>

<script>
   import { mdiClose } from '@mdi/js'
   import { useUserStore } from '@/stores/user.js'

   export default {
      setup () {
         const userStore = useUserStore()

         return { userStore }
      },
      data () {
         return {
            display: false,
            message: '',
            mdiClose,
         }
      },
      watch: {
         'userStore.notification' (value) {
            if (value !== null) {
               this.display = true
               this.message = value
               this.userStore.showNotification(null)
            }
         },
      },
   }
</script>
