import { HTTP } from '@/http.js'

export async function getMappingByID (scriptID, payload) {
   const { data } = await HTTP.get(`/scripts/${scriptID}/mapping`, {
      params: payload,
   })
   return data
}

export async function getScripts () {
   const { data } = await HTTP.get('/scripts')
   return data
}

export async function getScriptByID (scriptID) {
   const { data } = await HTTP.get(`/scripts/${scriptID}`)
   return data
}

export async function updateScriptByID (scriptID, payload) {
   const { data } = await HTTP.put(`/scripts/${scriptID}`, payload)
   return data
}

export async function getScriptsByCustomer (customerID) {
   const { data } = await HTTP.get('/scripts/customer', {
      params: {
         customerID,
      },
   })
   return data
}

export async function getScriptFilters (scriptID) {
   const { data } = await HTTP.get(`/scripts/${scriptID}/filters`)
   return data
}

export async function getVariablesByID (scriptID) {
   const { data } = await HTTP.get(`/scripts/${scriptID}/variables`)
   return data
}

export async function getTimesByID (scriptID) {
   const { data } = await HTTP.get(`/scripts/${scriptID}/times`)
   return data
}

export async function updateTimesByID (scriptID, timeWindows) {
   const { data } = await HTTP.put(`/scripts/${scriptID}/times/`, {
      timeWindows,
   })
   return data
}

export async function deleteTimesByID (scriptID, timeWindowID) {
   const { data } = await HTTP.delete(`/scripts/${scriptID}/times/${timeWindowID}`)
   return data
}

export async function addTimesByID (scriptID, timeWindowID) {
   const { data } = await HTTP.post(`/scripts/${scriptID}/times/${timeWindowID}`, {})
   return data
}

export async function getSharedTimesByID (scriptID, timeWindowID) {
   const { data } = await HTTP.get(`/scripts/${scriptID}/times/${timeWindowID}/shared`)
   return data
}

export function downloadScriptExample (scriptID) {
   return HTTP.post(`/scripts/${scriptID}/example`, {}, {
      responseType: 'blob',
   })
}

export async function getHolidays (language) {
   const { data } = await HTTP.get(`/scripts/holiday/${language}`)
   return data
}
