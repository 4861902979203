import { defineStore } from 'pinia'
import { updateLocale } from '@/api/login.js'

export const useLocaleStore = defineStore('locale', {
   persist: true,
   state: () => ({
      locale: 'nl',
   }),
   actions: {
      async setLocale (locale) {
         this.locale = locale

         await updateLocale({
            locale,
         })
      },
   },
})
