import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Vuetify from 'vuetify/lib'
import { Ripple } from 'vuetify/lib/directives'
import { Chart, registerables } from 'chart.js'
import { MatrixElement } from 'chartjs-chart-matrix'
import { WordElement } from 'chartjs-chart-wordcloud'
import { PiniaVuePlugin, createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import App from '@/App.vue'
import router from '@/router/router.js'
import nlTranslations from '@/locales/nl.js'

/** import google stuff **/
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import 'chartjs-adapter-date-fns'

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

Vue.use(PiniaVuePlugin)
Vue.use(VueI18n)

// register only the chart elements we use
Chart.register(
   ...registerables,
   MatrixElement,
   WordElement,
)
// Create VueI18n instance with options
const i18n = new VueI18n({
   locale: 'nl',
   fallbackLocale: 'nl',
   messages: {
      nl: nlTranslations,
   },
})

Vue.use(Vuetify, {
   directives: {
      Ripple,
   },
})

const vuetify = new Vuetify({
   icons: {
      iconfont: 'mdiSvg',
   },
   theme: {
      themes: {
         light: {
            primary: '#1993d2',
            secondary: '#80b6e2',
            accent: '#f18b1d',
            error: '#FF5252',
            info: '#2196F3',
            success: '#4CAF50',
            warning: '#FFC107',
         },
      },
   },
})

/**
 * Enable/Disable tips
 * @type {boolean}
 */
Vue.config.productionTip = false

/* eslint-disable no-new */
new Vue({
   el: '#app',
   router,
   i18n,
   pinia,
   vuetify,
   render: h => h(App),
}).$mount('#app')

export default i18n
