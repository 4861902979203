import { HTTP } from '@/http.js'

export async function getSelectableContacts (customerID) {
   const { data } = await HTTP.get('/contacts/select', {
      params: {
         customerID,
      },
   })
   return data
}

export async function getContactsByCustomer (customerID) {
   const { data } = await HTTP.get('/contacts', {
      params: {
         customerID,
      },
   })
   return data
}

export async function getContact (contactID, customerID) {
   const { data } = await HTTP.get(`/contacts/${contactID}`, {
      params: {
         customerID,
      },
   })
   return data
}

export async function deleteContact (contactID, customerID) {
   const { data } = await HTTP.delete(`/contacts/${contactID}`, {
      data: {
         customerID,
      },
   })
   return data
}

export async function getSubscriptionsByID (contactID, customerID) {
   const { data } = await HTTP.get(`/contacts/${contactID}/subscriptions`, {
      params: {
         customerID,
      },
   })
   return data
}

export async function updateSubscriptionsByID (contactID, customerID, subscription) {
   const { data } = await HTTP.patch(`/contacts/${contactID}/subscriptions`, {
      subscription,
      customerID,
   })
   return data
}

export async function updateContact (contactID, customerID, contact) {
   const { data } = await HTTP.patch(`/contacts/${contactID}`, {
      customerID,
      contact,
   })
   return data
}

export async function addContact (customerID, contact) {
   const { data } = await HTTP.post('/contacts', {
      customerID,
      contact,
   })
   return data
}
