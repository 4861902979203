import axios from 'axios'
import ReconnectingWebSocket from 'reconnecting-websocket'
import router from '@/router/router.js'
import i18n from '@/main.js'
import { useUserStore } from '@/stores/user.js'

let baseURL, scheme, socket

if (process.env.NODE_ENV === 'production') {
   baseURL = `${window.location.hostname}` // linked docker container api
   scheme = 'https://'
   socket = 'wss://'
} else {
   baseURL = `${window.location.hostname}:3000` // linked docker container api
   scheme = 'http://'
   socket = 'ws://'
}

const http = axios.create({
   baseURL: `${scheme}${baseURL}/api`,
   withCredentials: true,
})

let cancelSource = axios.CancelToken.source()

http.interceptors.request.use(
   config => {
      config.cancelToken = cancelSource.token
      return config
   },
)

http.interceptors.response.use(
   async function (response) {
      return Promise.resolve(response)
   },
   async function (error) {
      if (axios.isCancel(error)) {
         return Promise.reject(error)
      }
      if (error.response) {
         switch (error.response.status) {
            case 401: {
               location.replace('/')
               break
            }
            case 500: {
               if (router.currentRoute.path !== '/maintenance') {
                  const user = useUserStore()
                  await user.showNotification(i18n.t('shared.errorSomethingWentWrong'))
               }
               break
            }
            case 503: {
               if (process.env.NODE_ENV === 'production') {
                  await router.push('/maintenance')
               }
               break
            }
         }
         return Promise.reject(error.response)
      } else {
         /** als de client wel internet heeft dan is nginx/fe-api misschien down **/
         if (navigator.onLine === true) {
            if (process.env.NODE_ENV === 'production') {
               await router.push('/maintenance')
            }
         }
         return Promise.reject(error)
      }
   },
)

export const HTTP = http
export function createSocket (namespace = '') {
   return new ReconnectingWebSocket(`${socket}${baseURL}/api/${namespace}`)
}
export function cancelRequests () {
   cancelSource.cancel()
   cancelSource = axios.CancelToken.source()
}
