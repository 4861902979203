import { HTTP } from '@/http.js'

export function generateExcelReport (payload) {
   return HTTP.post('/reports/excel', payload, {
      responseType: 'blob',
   })
}

export function generateCsvReport (payload) {
   return HTTP.post('/reports/csv', payload, {
      responseType: 'blob',
   })
}

export function getCaseReportsPdf (payload) {
   return HTTP.post('/reports/case/pdf', payload, {
      responseType: 'blob',
   })
}

export async function getCaseReportsByType (type, payload) {
   const { data } = await HTTP.get(`/reports/case/${type}`, {
      params: payload,
   })
   return data
}

export async function getIdealReportsByType (type, payload) {
   const { data } = await HTTP.get(`/reports/ideal/${type}`, {
      params: payload,
   })
   return data
}

export async function getIdealCounterByType (type, customerID, scriptID) {
   const { data } = await HTTP.get('/reports/ideal/counter', {
      params: {
         customerID,
         scriptID,
         type,
      },
   })
   return data
}

export async function getIdealTableData (payload) {
   const { data } = await HTTP.get('/reports/ideal/payments/info', {
      params: payload,
   })
   return data
}

export async function getIdealHeatmap (customerID, scriptID) {
   const { data } = await HTTP.get('/reports/ideal/heatmap', {
      params: {
         customerID,
         scriptID,
      },
   })
   return data
}

export async function getMailReportsByType (type, payload) {
   const { data } = await HTTP.get(`/reports/mail/${type}`, {
      params: payload,
   })
   return data
}

export async function getMailCounterByType (type, customerID, scriptID) {
   const { data } = await HTTP.get('/reports/mail/counter', {
      params: {
         customerID,
         scriptID,
         type,
      },
   })
   return data
}

export async function getMailTableData (payload) {
   const { data } = await HTTP.get('/reports/mail/errors/info', {
      params: payload,
   })
   return data
}

export async function getWhatsAppReportsByType (type, payload) {
   const { data } = await HTTP.get(`/reports/whatsapp/${type}`, {
      params: payload,
   })
   return data
}

export async function getWhatsAppCounterByType (type, customerID, scriptID) {
   const { data } = await HTTP.get('/reports/whatsapp/counter', {
      params: {
         customerID,
         scriptID,
         type,
      },
   })
   return data
}

export async function getWhatsAppTableData (payload) {
   const { data } = await HTTP.get('/reports/whatsapp/errors/info', {
      params: payload,
   })
   return data
}

export async function getReportableBatches (scriptID, customerID) {
   const { data } = await HTTP.get('/reports/batch', {
      params: {
         scriptID,
         customerID,
      },
   })
   return data
}

export async function getBatchMapping (batchID, customerID) {
   const { data } = await HTTP.get('/reports/batch/mapping', {
      params: {
         batchID,
         customerID,
      },
   })
   return data
}

export async function getScriptMapping (scriptID, customerID) {
   const { data } = await HTTP.get('/reports/script/mapping', {
      params: {
         scriptID,
         customerID,
      },
   })
   return data
}

export async function getBatchOutput (payload) {
   const { data } = await HTTP.post('/reports/batch/output', payload)
   return data
}

export async function getScriptOutput (payload) {
   const { data } = await HTTP.post('/reports/script/output', payload)
   return data
}

export async function getCallReports (payload) {
   const { data } = await HTTP.get('/reports/calls', {
      params: payload,
   })
   return data
}

export async function getCallPerformance (payload) {
   const { data } = await HTTP.get('/reports/calls/performance', {
      params: payload,
   })
   return data
}

export async function getCallAgents (payload) {
   const { data } = await HTTP.get('/reports/calls/agents', {
      params: payload,
   })
   return data
}

export async function getCallQueues (customerID, teams) {
   const { data } = await HTTP.get('/reports/calls/queues', {
      params: {
         customerID,
         teams: JSON.stringify(teams),
      },
   })
   return data
}

export async function getCallTeams (customerID) {
   const { data } = await HTTP.get('/reports/calls/teams', {
      params: {
         customerID,
      },
   })
   return data
}
